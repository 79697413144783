<template>
	<div :class="{ 'h-100' : isAnySectionActive }">
		<aq-modal name="multi-edit-line-item-modal">
			<multi-edit-line-item-modal
				ref="multi-edit-line-item-modal"
				:deductions="claimDeductions"
				:policy-sections="allPolicySections"
				:conditions="conditions"
				:multicondition="multicondition"
				:selected-line-item="recognizedData.items[selectedTreatmentIds[0]]"
				@finish="onSaveModal"
			/>
		</aq-modal>
		<aq-modal
			name="add-claim-condition"
			v-if="multicondition"
		>
			<add-claim-condition-modal
				@add-claim-condition="addClaimCondition"
				:claim-id="claimId"
			/>
		</aq-modal>
		<div
			v-if="isAddingNewClaim && !isReadOnlyMode"
			class="h-100"
		>
			<add-claim-layout
				:claim-id="claimId"
				:locale="locale"
				:is-read-only-mode="isReadOnlyMode"
				@close="closeAddClaimLayout"
				@create="createNewClaim"
			/>
		</div>
		<aq-modal name="treatment-lines-modal">
			<treatment-lines-modal
				data-qa="invoiceItemsScan_modal_treatmentLinesModal"
				:selected-line-item="selectedItem"
				:policy-sections="allPolicySections"
				:conditions="conditions"
				:locale="locale"
				:deductions="claimDeductions"
				@create-line-item-copies="onCreateLineItemCopies"
			/>
		</aq-modal>
		<aq-sandwich
			v-if="!isAddingNewClaim"
			header="Invoice Details"
			sub-header="Please check the extracted data and make any corrections"
			ref="invoiceSandwich"
			class="invoice-sandwich d-flex flex-column"
			:active-container-class="isProcessStep ? 'active-sandwich-height': 'h-100'"
			content-class="flex-grow-1 d-flex flex-column pr-5"
			is-active-on-start
			@change-state="onChangeInvoiceSandwichState"
		>
			<template slot="content">
				<div
					ref="container"
					class="section-content items-list p-0 my-10"
				>
					<div class="container-fluid">
						<div class="row no-gutters pb-20 fs-12">
							<div
								v-if="duplicateData.length > 0"
								class="duplicate-invoice-icon"
								v-tooltip="'Duplicate Invoice Lines Detected'"
							>
								<i class="mx-auto fas fa-exclamation" />
							</div>
							<div
								v-if="!isReadOnlyMode"
								class="col d-flex justify-content-end"
							>
								<div
									v-if="isProcessStep"
									class="px-10 d-flex align-items-center justify-content-end"
								>
									<div
										v-if="multicondition"
										@click="onAddClaimCondition()"
										class="btn btn-sm btn-primary d-flex align-items-center py-7 pl-20 close"
									>
										<i class="aqv-circle-plus mr-15 fs-30" />
										<span>Add New Ailment</span>
									</div>
									<div
										v-else
										@click="onAddClaim()"
										class="btn btn-sm btn-primary d-flex align-items-center py-7 pl-20 close"
									>
										<i class="aqv-circle-plus mr-15 fs-30" />
										<span>Create New Claim</span>
									</div>
								</div>
								<div
									class="d-flex px-10 py-5 align-items-center multiple-treatments rounded-4"
									:class="{ 'active': isMultiEditMode && selectedTreatmentIds.length }"
								>
									<aq-checkbox
										class="round large thin mr-10"
										:value="isMultiEditMode"
										@input="onSelectMultiEdit"
									/>
									<div>
										{{ isMultiEditMode && selectedTreatmentIds.length ? 'Edit selected treatments' : 'Select Multiple Treatments' }}
									</div>
									<i
										v-if="isMultiEditMode && selectedTreatmentIds.length"
										@click="onOpenModal"
										class="fas fa-pencil-alt ml-10 pt-5 cursor-pointer"
									/>
								</div>
							</div>
						</div>

						<div class="d-flex no-gutters pb-10 fs-12">
							<div
								@click="highlightCustomerName"
								class="mr-5 flex-grow-1"
							>
								<aq-form-input
									data-qa="invoiceItemsScan_input_customerName"
									label="Customer Name"
									v-model="recognizedData.customerName.value"
									@input="$v.recognizedData.customerName.$touch"
									:disabled="isReadOnlyMode"
								/>
								<aq-form-input-error
									class="mt-4"
									:warning-messages="errorMessages.customerName"
									:validator="$v.recognizedData.customerName"
									property="value"
								/>
							</div>
							<div
								@click="highlightInvoiceNumber"
								class="mr-5 flex-grow-1"
							>
								<aq-form-input
									data-qa="invoiceItemsScan_input_invoiceNumber"
									label="Invoice Number"
									v-model="recognizedData.invoiceNumber.value"
									@input="onInputInvoiceNumber"
									:is-valid="!$v.recognizedData.invoiceNumber.$error"
									:disabled="isReadOnlyMode"
								/>
								<aq-form-input-error
									class="mt-4"
									:error-messages="errorMessages.invoiceNumber"
									:validator="$v.recognizedData.invoiceNumber"
									property="value"
								/>
							</div>
							<div
								@click="highlightVendorName"
								class="flex-grow-1"
							>
								<aq-form-input
									data-qa="invoiceItemsScan_input_vetPractice"
									label="Vet Practice"
									v-model="recognizedData.vendorName.value"
									@input="$v.recognizedData.vendorName.$touch"
									:is-valid="!$v.recognizedData.vendorName.$error"
									:disabled="isReadOnlyMode"
								/>
								<aq-form-input-error
									class="mt-4"
									:error-messages="errorMessages.vendorName"
									:validator="$v.recognizedData.vendorName"
									property="value"
								/>
							</div>
						</div>
						<div class="row no-gutters pb-11 fs-12">
							<div
								class="d-flex align-items-center pl-8"
								:class="gridSizes[0]"
							>
								<aq-checkbox
									v-if="isSelectMultipleDisabled"
									class="round medium thin"
									v-model="toggleAllState"
									:disabled="isReadOnlyMode"
								/>
								<aq-checkbox
									v-else
									class="round medium thin"
									v-model="toggleMultiEditAllState"
								/>
								<div class="ml-13">
									Date
								</div>
							</div>

							<div
								class="pl-5 d-flex align-items-center"
								:class="gridSizes[1]"
							>
								Description
							</div>
							<div
								class="d-flex align-items-center"
								:class="gridSizes[2]"
							>
								Disc. {{ currencySymbol }}
							</div>
							<div
								class="d-flex align-items-center"
								:class="gridSizes[3]"
							>
								Tax
							</div>
							<div
								class="d-flex align-items-center"
								:class="gridSizes[4]"
							>
								Amount
							</div>
							<div
								v-if="multicondition"
								class="d-flex align-items-center"
								:class="gridSizes[5]"
							>
								Ailment
							</div>
							<div
								class="d-flex align-items-center"
								:class="gridSizes[6]"
							>
								Section
							</div>
							<div
								class="d-flex align-items-center"
								:class="gridSizes[7]"
							/>
						</div>

						<div>
							<div
								v-if="allPolicySections && claimDeductions"
								class="position-relative"
							>
								<div
									v-for="(group, index) in lineGroups"
									:key="index"
									data-qa="invoiceItemsScan_parent_container"
									:class="[group.items.length > 1 ? 'lines-group': 'line',
										group.items.some(i=> i.index === selectedItemIndex) ? 'highlighted' :
										checkLineDuplicate(group.items[0]) ? 'duplicate' :'default']"
								>
									<invoice-line-item
										data-qa="invoiceLineItem_line"
										v-for="item in group.items"
										:key="item.index"
										:data-item="item"
										:deductions="claimDeductions"
										:policy-sections="allPolicySections"
										:conditions="conditions"
										@startEdit="scrollTop()"
										@finish="onFinishEdit($event, item.index)"
										@line-type-selected="onLineTypeSelected($event, item.index)"
										@delete="onDelete(item.index)"
										@toggle="onToggle($event, item.index)"
										@multiEditToggle="onMultipleTreatmentSelected($event, item.index)"
										@setValidity="onSetValidity($event, item.index)"
										@highlight="onItemHighlight($event, item.index)"
										:selected="selectedItemIndex === item.index"
										:locale="locale"
										:grid-sizes="gridSizes"
										:multicondition="multicondition"
										:is-edit-multi-mode="!isSelectMultipleDisabled"
										:is-selected-multi-edit="selectedTreatmentIds.indexOf(item.index) !== -1"
										:is-excluded-multi-edit="isItemExcludedFromMultiEdit(item)"
										:pre-applied-discount="getPreAppliedDiscount(item.index)"
										:is-read-only-mode="isReadOnlyMode"
										@show-treatment-lines-modal="onOpenTreatmentLinesModal"
										:is-duplicate="checkLineDuplicate(item)"
									/>
								</div>
								<invoice-line-item
									data-qa="invoiceLineItem_line"
									v-if="isAddingNew"
									:data-item="newItem"
									:deductions="claimDeductions"
									:policy-sections="allPolicySections"
									:conditions="conditions"
									:locale="locale"
									:grid-sizes="gridSizes"
									:multicondition="multicondition"
									@finish="onFinishCreate($event)"
									@cancel="onCancelCreate()"
									@startEdit="scrollTop()"
									is-new
								/>
							</div>
							<invoice-total-discount-line
								label="Total Discount"
								:recognized-field="recognizedData.totalDiscount"
								:total-discount="totalDiscount"
								:is-read-only-mode="isReadOnlyMode || invoiceRecognizedDiscountItemsSum !== 0"
								:selected-line-items="selectedLineItems"
								:show-discount-split="isSplitDiscountMode"
								@on-total-item-edit="onTotalDiscountEdit"
								@select-split-discount="onSelectSplitDiscount"
								@discounts-split-update="discountsSplit = $event"
							/>
							<invoice-total-item-edit
								:label="`Total Tax ${taxesIncluded ? '(included)' :'' }`"
								:included-field="recognizedData.taxesIncluded"
								:recognized-field="recognizedData.totalTax"
								:invoice-recognized-amount="invoiceRecognizedTotalTax"
								:invoice-recognized-items-sum="invoiceRecognizedTaxItemsSum"
								:initial-total-sum="totalTax"
								:is-read-only-mode="isReadOnlyMode"
								@on-total-item-edit="onTotalTaxEdit"
							/>
							<div class="row no-gutters attribute py-16 mb-10 align-items-center">
								<div class="col-6 pl-10">
									Total
								</div>
								<div class="col-2">
									{{ invoiceItemsSum | currency(locale) }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="!isReadOnlyMode"
					class="section-footer"
				>
					<div class="container-fluid d-flex justify-content-end">
						<button
							v-if="$can.EditInvoiceRecognition"
							class="btn btn-primary btn-radius--less mr-10"
							:disabled="isAddingNew"
							@click="addNewItem()"
						>
							Add Item
						</button>
						<button
							class="btn btn-primary btn-radius--less"
							:disabled="isInvoiceInvalid || isInvoiceNumberInvalid || isVendorNameInvalid || recognizedData.items.length <= 0"
							@click="onProceedInvoiceItems()"
						>
							Proceed
						</button>
					</div>
				</div>
			</template>
		</aq-sandwich>
		<aq-sandwich
			v-if="!isAddingNewClaim && !isReadOnlyMode"
			header="Claim Details"
			sub-header="Please check entered claim data"
			ref="claimDataSandwich"
			class="invoice-sandwich"
			:active-container-class="isProcessStep ? 'active-sandwich-height': 'h-100'"
			content-class="flex-grow-1 d-flex flex-column pr-5"
			@change-state="onChangeClaimDataSandwichState"
		>
			<template slot="content">
				<div
					class="section-content"
				>
					<div
						class="container"
						v-if="claimBasic"
					>
						<div
							class="row no-gutters fs-14 my-10 flex-column attribute p-10"
							v-if="countOfErrors || countOfWarnings"
						>
							<div
								v-if="countOfErrors"
								class="mb-6"
							>
								{{ countOfErrors }} data validation
								<span class="text-danger"> {{ 'error' | pluralize(countOfErrors) }} </span> detected
							</div>
							<div v-if="countOfWarnings">
								{{ countOfWarnings }} data validation
								<span class="text-warning">{{ 'warning' | pluralize(countOfWarnings) }}</span> detected
							</div>
						</div>
						<div class="row no-gutters mb-26 attribute align-items-center p-10">
							<div class="col-4">
								<h4>{{ dateOfLossLabel }}</h4>
							</div>
							<div class="col-8 px-20 fs-14">
								<span>{{ claimBasic.dateOfLoss | shortDate }}</span>
							</div>
						</div>
						<div class="row no-gutters mb-26 align-items-center attribute px-10 py-2">
							<div class="col-4">
								<h4>Treatment From</h4>
							</div>
							<div class="col-8">
								<aq-form-input
									data-qa="invoiceItemsScan_input_treatmentFrom"
									label="Treatment From"
									type="date"
									v-model="claimBasic.treatmentStart"
									@input="$v.claimBasic.treatmentStart.$touch"
									:is-valid="!$v.claimBasic.treatmentStart.$error"
									:highlighted="highlightedTreatmentStartDeath"
									:disabled="isReadOnlyMode"
								/>
								<aq-form-input-error
									class="error mt-4 px-20"
									:error-messages="errorMessages"
									:validator="$v.claimBasic"
									property="treatmentStart"
								/>
							</div>
						</div>
						<div class="row no-gutters attribute align-items-center px-10 py-2 mb-26">
							<div class="col-4">
								<h4>Treatment To</h4>
							</div>
							<div class="col-8">
								<aq-form-input
									data-qa="invoiceItemsScan_input_treatmentTo"
									label="Treatment To"
									type="date"
									v-model="claimBasic.treatmentEnd"
									@input="$v.claimBasic.treatmentEnd.$touch"
									:is-valid="!$v.claimBasic.treatmentEnd.$error"
									:highlighted="highlightedTreatmentEnd"
									:disabled="isReadOnlyMode"
								/>
								<aq-form-input-error
									class="error mt-4 px-20"
									:error-messages="errorMessages"
									:validator="$v.claimBasic"
									property="treatmentEnd"
								/>
							</div>
						</div>
						<div class="row no-gutters attribute align-items-center px-10 py-2 mb-26">
							<div class="col-4">
								<h4>Amount</h4>
							</div>
							<div class="col-8">
								<aq-form-input
									data-qa="invoiceItemsScan_input_amount"
									label="Amount"
									type="money"
									v-model="claimBasic.amount"
									@input="$v.claimBasic.amount.$touch"
									:is-valid="!$v.claimBasic.amount.$error"
									:disabled="isReadOnlyMode"
									:locale="locale"
								/>
								<aq-form-input-error
									class="error position-relative mt-4 px-20"
									:error-messages="errorMessages"
									:validator="$v.claimBasic"
									property="amount"
								/>
								<span
									v-if="!totalAmountMatchInvoices"
									class="text-warning px-20"
								>
									{{ errorMessages.notMatchAmountWarning }}
								</span>
							</div>
						</div>
						<div
							v-if="isNoActiveCoverPeriodError"
							class="row no-gutters bg-warning-darken my-10 p-10"
						>
							<span class="fs-14 mx-auto">{{ errorMessages.noCoverWarning }}</span>
						</div>
					</div>
					<div
						v-if="!isReadOnlyMode"
						class="section-footer mx-14"
					>
						<div class="container-fluid d-flex justify-content-end">
							<button
								class="btn btn-primary btn-radius--less"
								:disabled="isClaimDetailsInvalid || isNoActiveCoverPeriodError"
								@click="onProceedClaimDetails"
							>
								Proceed
							</button>
						</div>
					</div>
				</div>
			</template>
		</aq-sandwich>
	</div>
</template>
<script>
import ClaimService from '@commonServices/claimService';
import { currency } from '@commonServices/utils/filters';
import groupBy from 'lodash.groupby';
import { toInvoiceScanItem, toClaimConditionDescription } from '@commonServices/utils/converter';
import { maxValue, required } from 'vuelidate/lib/validators';
import { lessThenOrEqual, maxAmount, moreThen, moreThenOrEqual } from '@commonServices/utils/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { areDatesEqual, fromStringToDate } from '@commonServices/utils/dateUtils';
import ClaimInput from '@commonServices/models/ClaimInput';
import { getCountOfErrorsForValidator } from '@commonServices/utils/general';
import maxLength from 'vuelidate/lib/validators/maxLength';
import eventBus from '@commonServices/eventBus';
import deductionService from '@commonServices/deductionService';
import { mapActions, mapState, mapGetters } from 'vuex';
import { getCurrencyByLocale } from '@commonServices/settings/localeSettings';
import InvoiceTotalItemEdit from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceTotalItemEdit';
import InvoiceTotalDiscountLine from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceTotalDiscountLine';
import AddClaimConditionModal from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/AddClaimConditionModal';
import AddClaimLayout from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/AddClaimLayout';
import MultiEditLineItemModal from '@commonView/Shared/MultiEditLineItemModal';
import StepNameEnum from '@commonServices/models/steps/StepNameEnum';
import { ClaimFormType } from '@commonServices/models/ClaimFormType';
import { InvoiceLineType } from '@commonServices/models/InvoiceLineType';
import TreatmentLinesModal from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/TreatmentLinesModal';
import selectMultipleModeEnum from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/SelectMultipleModeEnum';

export default {
	name: 'InvoiceItemsScan',
	components: {
		InvoiceTotalItemEdit,
		MultiEditLineItemModal,
		AddClaimConditionModal,
		TreatmentLinesModal,
		AddClaimLayout,
		InvoiceTotalDiscountLine,
	},
	data () {
		return {
			validated: [],
			isInvoiceInvalid: false,
			allPolicySections: undefined,
			conditions: [],
			selectedItemIndex: null,
			isAddingNew: false,
			newItem: null,
			claimBasic: {},
			conditionType: null,
			isNoActiveCoverPeriodError: false,
			petId: null,
			claimData: null,
			invoiceActive: true,
			claimDataActive: false,
			claimDeductions: [],
			isLineItemTaxChanged: false,
			totalTax: null,
			totalDiscountValue: null,
			selectedTreatmentIds: [],
			selectedItem: null,
			isAddingNewClaim: false,
			selectMultipleMode: selectMultipleModeEnum.disabled,
			discountsSplit: [],
			isInvoiceNumberInvalid: false,
		};
	},
	props: {
		recognizedData: {
			type: Object,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
		claimId: {
			type: Number,
			required: false,
			default: null,
		},
		fileId: {
			type: Number,
			required: true,
		},
		duplicateData: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	async mounted () {
		const [policySections, claimData, claimDeductions] = await Promise.all([ClaimService.getPolicySections(this.claimId), ClaimService.getClaim(this.claimId), deductionService.getClaimDeductions(this.claimId)]);
		this.allPolicySections = policySections;
		this.claimDeductions = claimDeductions;
		const { basicData, conditions, petId } = claimData;
		this.conditions = conditions.map(c => ({
			id: c.id,
			description: toClaimConditionDescription(c),
		}));
		this.claimBasic = basicData;
		this.conditionType = conditions[0].conditionType;
		this.petId = petId;
		this.claimData = claimData;
		this.$v.recognizedData.customerName.$touch();
		this.changeBasicClaimAmount(this.claimBasic.amount);
		this.totalDiscount = this.invoiceRecognizedTotalDiscount;
	},
	validations () {
		const dateMaxValue = this.claimData?.formType === ClaimFormType.PreAuthorisation ? () => true : maxValue(this.dateForFutureValidation); // disable 'dateInFuture' check for pre-authorisation claims

		return {
			claimBasic: {
				treatmentStart: {
					required,
					maxValue: dateMaxValue,
					minValue: moreThenOrEqual(this.claimBasic.dateOfLoss),
					deathValue: lessThenOrEqual(this.claimBasic.dateOfDeath),
					matchInvoiceTreatmentStart: lessThenOrEqual(this.invoiceLeastDate),
				},
				treatmentEnd: {
					required,
					maxValue: dateMaxValue,
					treatmentStart: moreThenOrEqual(this.claimBasic.dateOfLoss),
					minValue: moreThenOrEqual(this.claimBasic.treatmentStart),
					deathValue: lessThenOrEqual(this.claimBasic.dateOfDeath),
					matchInvoiceTreatmentEnd: moreThenOrEqual(this.invoiceGreatestDate),
				},
				amount: {
					required,
					minValue: moreThen(0),
					maxAmount: maxAmount(16),
				},
			},
			recognizedData: {
				customerName: {
					value: {
						required,
						maxLength: maxLength(16),
						matchingExisting: (value) => value === this.existingCustomerName,
					},
				},
				invoiceNumber: {
					value: {
						required,
						maxLength: maxLength(64),
					},
				},
				vendorName: {
					value: {
						required,
						maxLength: maxLength(1000),
					},
				},
			},
		};
	},
	computed: {
		...mapState(['interactionContext', 'currentClaimStepName']),
		...mapGetters(['dateOfLossLabel', 'dateForFutureValidation']),
		lineGroups () {
			const groupedLines = groupBy(this.recognizedData.items.map((item, index) => ({ ...item, index })), 'parentId');
			return groupedLines.null?.reduce((aggr, next) => {
				const children = groupedLines[next.id] ?? []; // try find children
				const items = [next, ...children];
				return [...aggr, { items }];
			}, []);
		},
		gridSizes () {
			return this.multicondition ? ['col-2', 'col-3', 'col-1', /* 'col-1', */ 'col-1', 'col-1', 'col-1', 'col-1', 'col-2'] : ['col-2', 'col-3', 'col-1', /* 'col-1',  */ 'col-1', 'col-1', 'col-0', 'col-2', 'col-2'];
		},
		existingCustomerName () {
			return `${this.interactionContext.customer.firstName} ${this.interactionContext.customer.lastName}`;
		},
		isProcessStep () {
			return this.currentClaimStepName === StepNameEnum.ClaimProcess;
		},
		errorMessages () {
			return {
				treatmentStart: {
					required: ValidationMessages.required,
					maxValue: ValidationMessages.dateInFuture,
					minValue: ValidationMessages.dateShouldBeAfter(this.dateOfLossLabel),
					deathValue: ValidationMessages.dateCannotBeAfter('Date of Death'),
					matchInvoiceTreatmentStart: 'There are invoice dates before treatment start date',
				},
				treatmentEnd: {
					required: ValidationMessages.required,
					maxValue: ValidationMessages.dateInFuture,
					treatmentStart: ValidationMessages.dateShouldBeAfter(this.dateOfLossLabel),
					minValue: ValidationMessages.dateShouldBeAfter('Treatment From'),
					deathValue: ValidationMessages.dateCannotBeAfter('Date of Death'),
					matchInvoiceTreatmentEnd: 'There are invoice dates after treatment end date',
				},
				amount: {
					required: ValidationMessages.required,
					minValue: ValidationMessages.greaterThanZero('Claim Amount'),
					maxAmount: ValidationMessages.maxAmount,
				},
				invoiceNumber: {
					value: {
						required: ValidationMessages.required,
						maxLength: ValidationMessages.maxLength(64),
					},
				},
				vendorName: {
					value: {
						required: ValidationMessages.required,
						maxLength: ValidationMessages.maxLength(1000),
					},
				},
				customerName: {
					value: {
						matchingExisting: `Customer name does not match existing: ${this.existingCustomerName}`,
					},
				},
				noCoverWarning: 'No Cover in force - Reject Claim',
				notMatchAmountWarning: 'The total treatments do not match the claim amount',
			};
		},
		toggleAllState: {
			get () {
				return !this.recognizedData.items.some(item => item.excluded.value);
			},
			set (newValue) {
				this.$emit('toggle-all', newValue);
			},
		},
		toggleMultiEditAllState: {
			get () {
				return this.recognizedData.items.filter(item => !this.isItemExcludedFromMultiEdit(item))
					.length === this.selectedTreatmentIds.length;
			},
			set (newValue) {
				this.onToggleSelectedAllTreatments(newValue);
			},
		},
		totalDiscount: {
			get () {
				return this.invoiceRecognizedDiscountItemsSum !== 0
					? this.invoiceRecognizedDiscountItemsSum
					: this.totalDiscountValue;
			},
			set (newValue) {
				this.totalDiscountValue = newValue;
			},
		},
		invoiceItemsSum () {
			const sum = this.recognizedData.items.filter(item => !item.excluded.value && item.itemType?.value !== InvoiceLineType.Discount)
				.reduce((sum, item) => sum + this.getInvoiceLineAmount(item), 0);

			const totalTax = this.invoiceRecognizedTaxItemsSum ? 0 : (this.totalTax == null ? this.invoiceRecognizedTotalTax : this.totalTax);
			return parseFloat(currency(sum + (this.taxesIncluded ? 0 : totalTax) - this.totalDiscount, this.locale, true));
		},
		invoiceRecognizedTotalTax () {
			return parseFloat(currency(this.recognizedData.totalTax?.value || 0, this.locale, true));
		},
		taxesIncluded () {
			return !!this.recognizedData.taxesIncluded?.value || !!this.invoiceRecognizedTaxItemsSum;
		},
		invoiceRecognizedTaxItemsSum () {
			const sum = this.recognizedData.items.filter(item => !item.excluded.value)
				.reduce((sum, item) => sum + this.getInvoiceLineTaxValue(item), 0);
			return parseFloat(currency(sum, this.locale, true));
		},
		invoiceRecognizedTotalDiscount () {
			const positiveTotalDiscountValue = Math.abs(this.recognizedData.totalDiscount?.value || 0);
			return parseFloat(currency(positiveTotalDiscountValue, this.locale, true));
		},
		invoiceRecognizedDiscountItemsSum () {
			const sum = this.recognizedData.items.filter(item => !item.excluded.value)
				.reduce((sum, item) => sum + this.getInvoiceLineDiscountValue(item), 0);
			return parseFloat(currency(sum, this.locale, true));
		},
		highlightedTreatmentEnd: function () {
			return {
				dates: [
					this.invoiceGreatestDate ? this.invoiceGreatestDate : new Date(),
				],
			};
		},
		highlightedTreatmentStartDeath: function () {
			return {
				dates: [
					this.invoiceLeastDate ? this.invoiceLeastDate : new Date(),
				],
			};
		},
		invoiceItemsDate () {
			return this.recognizedData.items.filter(item => !item.excluded.value)
				.map(item => fromStringToDate(item.date.value));
		},
		invoiceLeastDate () {
			if (this.invoiceItemsDate.length === 0) {
				return null;
			}
			return new Date(Math.min(...this.invoiceItemsDate));
		},
		invoiceGreatestDate () {
			if (this.invoiceItemsDate.length === 0) {
				return null;
			}
			return new Date(Math.max(...this.invoiceItemsDate));
		},
		totalAmountMatchInvoices () {
			return this.invoiceItemsSum === this.claimBasic.amount;
		},
		isClaimDetailsInvalid () {
			const { treatmentStart, treatmentEnd, amount } = this.$v.claimBasic;
			return treatmentStart.$error
				|| treatmentEnd.$error
				|| amount.$error;
		},
		isVendorNameInvalid () {
			this.$v.recognizedData.vendorName.$touch();
			return this.$v.recognizedData.vendorName.value.$error;
		},
		isAnySectionActive () {
			return this.invoiceActive || this.claimDataActive;
		},
		countOfErrors () {
			let countOfErrors = 0;
			const validators = Object.keys(this.$v.claimBasic).filter(key => !key.includes('$'));
			for (const key of validators) {
				countOfErrors += getCountOfErrorsForValidator(this.$v.claimBasic, key);
			}
			return countOfErrors;
		},
		countOfWarnings () {
			return this.totalAmountMatchInvoices ? 0 : 1;
		},
		currencySymbol () {
			return getCurrencyByLocale(this.locale).symbol;
		},
		isSelectMultipleDisabled () {
			return this.selectMultipleMode === selectMultipleModeEnum.disabled;
		},
		isMultiEditMode () {
			return this.selectMultipleMode === selectMultipleModeEnum.multiEdit;
		},
		isSplitDiscountMode () {
			return this.selectMultipleMode === selectMultipleModeEnum.discountSplit;
		},
		selectedLineItems () {
			return this.selectedTreatmentIds.map(item => {
				const selectedItem = this.recognizedData.items[item];
				return ({
					index: item,
					amount: selectedItem.amount.value,
				});
			});
		},
	},
	watch: {
		async 'claimBasic.treatmentStart' (newValue, oldValue) {
			if (newValue === null) {
				this.isNoActiveCoverPeriodError = false;
				return;
			}
			this.$v.claimBasic.treatmentStart.$touch();
			if (this.$v.claimBasic.treatmentStart.$invalid) {
				return;
			}
			if (!areDatesEqual(newValue, oldValue)) {
				await this.checkActiveCoverPeriod();
			}
		},
		'invoiceRecognizedTaxItemsSum' () {
			this.isLineItemTaxChanged = true;
		},
	},
	methods: {
		...mapActions(['changeBasicClaimAmount']),
		highlightCustomerName () {
			const highlightSettings = {
				page: 1,
				boundingBox: this.recognizedData.customerName.boundingBox,
			};
			this.highlightArea(highlightSettings);
		},
		highlightInvoiceNumber () {
			const highlightSettings = {
				page: 1,
				boundingBox: this.recognizedData.invoiceNumber.boundingBox,
			};
			this.highlightArea(highlightSettings);
		},
		highlightVendorName () {
			const highlightSettings = {
				page: 1,
				boundingBox: this.recognizedData.vendorName.boundingBox,
			};
			this.highlightArea(highlightSettings);
		},
		checkLineDuplicate (line) {
			return this.duplicateData.some(item => item.amount === line.amount.value
                                                    && item.description === line.description.value
                                                    && item.date === line.date.value
                                                    && item.quantity === line.quantity.value);
		},
		async checkActiveCoverPeriod () {
			if (!this.claimBasic.treatmentStart || !this.conditionType) return;
			const conditionTypeId = this.conditionType.id;
			const res = await ClaimService.validatePolicy(this.petId, this.claimBasic.treatmentStart, this.claimBasic.dateOfLoss, conditionTypeId);
			this.isNoActiveCoverPeriodError = !res;
		},
		onProceedInvoiceItems () {
			this.onInputInvoiceNumber();
			if (this.isInvoiceNumberInvalid) {
				return;
			}

			this.$refs.invoiceSandwich.onToggle();
			this.$emit('invoice-passed', this.invoiceItemsSum, this.invoiceRecognizedTotalTax, this.invoiceRecognizedTaxItemsSum, this.totalTax, this.isLineItemTaxChanged, this.totalDiscountValue);
			this.$refs.claimDataSandwich.onToggle();
			this.$v.claimBasic.$touch();
		},
		onProceedClaimDetails () {
			this.$v.claimBasic.$touch();
			if (this.isClaimDetailsInvalid || this.isNoActiveCoverPeriodError) return;

			this.$refs.claimDataSandwich.onToggle();
			const apiClaimData = this.toApiClaimBasicData();
			const isBasicDataSame = this.claimBasic.dateOfLoss === this.claimData.basicData.dateOfLoss
																&& this.claimBasic.treatmentStart === this.claimData.basicData.treatmentStart
																&& this.claimBasic.treatmentEnd === this.claimData.basicData.treatmentEnd
																&& this.claimBasic.amount === this.claimData.basicData.amount;
			this.$emit('details-passed', { isBasicDataSame, claimData: apiClaimData });
		},
		onSetValidity (obj, index) {
			this.validated[index] = {
				index,
				isInvalid: obj.isInvalid,
			};
			this.isInvoiceInvalid = this.validated.some(x => x.isInvalid);
		},
		onToggle (value, index) {
			this.$emit('toggle', { value, index });
		},
		onItemHighlight (event, index) {
			this.selectedItemIndex = index;
			this.highlightArea(event);
		},
		highlightArea (highlightSettings) {
			this.$emit('highlight', highlightSettings);
		},
		onMultipleTreatmentSelected (value, index) {
			if (value) {
				this.selectedTreatmentIds.push(index);
			} else {
				const selectedTreatmentIdIndex = this.selectedTreatmentIds.indexOf(index);
				this.selectedTreatmentIds.splice(selectedTreatmentIdIndex, 1);
			}
		},
		isMultipleTreatmentSelected (index) {
			return this.selectedTreatmentIds.indexOf(index) !== -1;
		},
		onToggleSelectedAllTreatments (selectAll) {
			this.selectedTreatmentIds = [];
			if (selectAll) {
				this.recognizedData.items.forEach((item, index) => {
					if (!this.isItemExcludedFromMultiEdit(item)) {
						this.selectedTreatmentIds.push(index);
					}
				});
			}
		},
		onSelectSplitDiscount (isSelected) {
			this.onSelectMultipleModeUpdate(selectMultipleModeEnum.discountSplit, isSelected);
		},
		onSelectMultiEdit (isSelected) {
			this.onSelectMultipleModeUpdate(selectMultipleModeEnum.multiEdit, isSelected);
		},
		onSelectMultipleModeUpdate (mode, isSelected) {
			this.selectMultipleMode = isSelected ? mode : selectMultipleModeEnum.disabled;
			this.onToggleSelectedAllTreatments(false);
		},
		isItemExcludedFromMultiEdit (item) {
			return this.isSplitDiscountMode && item.itemType.value !== InvoiceLineType.Treatment;
		},
		getPreAppliedDiscount (index) {
			return this.isSplitDiscountMode
				? this.discountsSplit.find(item => item.index === index)
				: null;
		},
		addNewItem () {
			this.isAddingNew = true;
			const vetFeesPolicy = this.allPolicySections.find(policy => policy.description === 'Vet Fees');
			let defaultCondition = this.conditions[0];
			if (vetFeesPolicy) {
				defaultCondition = this.conditions.filter(c => c.id === vetFeesPolicy.claimConditionId)[0];
			}
			const defaults = {
				...vetFeesPolicy && { PolicySection: { fieldName: 'PolicySection', value: vetFeesPolicy } },
				...defaultCondition && { ClaimCondition: { fieldName: 'ClaimCondition', value: defaultCondition } },
			};
			this.newItem = toInvoiceScanItem(defaults);
			this.showDocumentPreview();
		},
		onFinishCreate (value) {
			this.isAddingNew = false;
			this.$emit('finish-edit', { value, newItem: this.newItem });
		},
		onFinishEdit (value, index) {
			this.$emit('finish-edit', { value, index });
		},
		onLineTypeSelected (value, index) {
			this.$emit('line-type-selected', { value, index });
		},
		onSaveModal (value) {
			this.$emit('finish-multi-edit', { indexes: this.selectedTreatmentIds, value });
			this.selectMultipleMode = selectMultipleModeEnum.disabled;
			this.onToggleSelectedAllTreatments(false);
		},
		onCancelCreate () {
			this.isAddingNew = false;
		},
		onDelete (index) {
			this.$emit('delete', index);
			this.validated.splice(index, 1);
		},
		onAddClaimCondition () {
			this.$modal.show('add-claim-condition');
		},
		addClaimCondition (newClaimCondition) {
			this.$modal.hide('add-claim-condition');
			ClaimService.getPolicySections(this.claimId).then(policySections => {
				this.allPolicySections = policySections;
				this.conditions.push({
					id: newClaimCondition.id,
					description: toClaimConditionDescription(newClaimCondition),
				});
				eventBus.$emit('invoice-panel-add-claim-condition', { newClaimCondition, policySections });
			});
		},
		onAddClaim () {
			this.isAddingNewClaim = !this.isAddingNewClaim;
		},
		closeAddClaimLayout () {
			this.isAddingNewClaim = false;
		},
		async createNewClaim (claimData) {
			await ClaimService.createLinkedClaim(this.claimId, this.fileId, claimData);
			this.isAddingNewClaim = false;
		},
		scrollTop () {
			this.$refs.container.scrollTop = 0;
			this.showDocumentPreview();
		},
		toApiClaimBasicData () {
			const saveAsDraft = this.claimData.conditions.some(cc => cc.ailment === null || cc.bodyPart === null || cc.conditionType === null);
			return new ClaimInput(
				this.claimBasic.description,
				this.claimBasic.dateOfLoss,
				this.claimBasic.dateOfDeath,
				this.claimBasic.treatmentStart,
				this.claimBasic.treatmentEnd,
				this.claimBasic.amount,
				this.claimData.conditions.map(cc => ({
					id: cc.id,
					ailmentId: cc.ailment?.id,
					bodyPartId: cc.bodyPart?.id,
					conditionTypeId: cc.conditionType?.id,
					parentConditionId: cc.parentConditionId,
					ignorePreExistingCondition: cc.wasIgnoredPreExistingCondition,
					ignoreNoProductCoverage: cc.wasIgnoredNoProductCoverage,
					ignoreNoCoverPeriod: cc.wasIgnoredNoCoverPeriod,
					ignoreWaitingPeriod: cc.wasIgnoredWaitingPeriod,
				})),
				this.claimData.vets.map(vet => vet.id),
				this.claimData.isRequestTriggered,
				saveAsDraft,
				false,
				[],
				null,
				this.claimData.formType,
				this.claimData.payees,
				null,
				true,
				this.claimData.wasIgnoredDuplicateClaim,
				this.claimData.wasIgnoredDuplicateInvoice,
			);
		},
		showDocumentPreview () {
			this.$emit('show-document-preview');
		},
		onChangeInvoiceSandwichState (state) {
			this.invoiceActive = state;
			this.$emit('show-close-button', this.invoiceActive);
			if (!this.invoiceActive) {
				this.showDocumentPreview();
			}
		},
		onChangeClaimDataSandwichState (state) {
			this.claimDataActive = state;
		},
		onTotalTaxEdit ({ value, included }) {
			this.totalTax = value;
			this.recognizedData.taxesIncluded.value = included;
			this.showDocumentPreview();
		},
		onTotalDiscountEdit ({ value }) {
			this.totalDiscount = value;

			if (this.discountsSplit?.length) {
				this.$emit('finish-discounts-split', this.discountsSplit);
			}
			this.showDocumentPreview();
		},
		onOpenModal () {
			this.$modal.show('multi-edit-line-item-modal');
		},
		onOpenTreatmentLinesModal (item) {
			this.selectedItem = item;
			this.$modal.show('treatment-lines-modal');
		},
		onCreateLineItemCopies (lineItemsCopies) {
			this.$emit('finish-split-edit', lineItemsCopies, this.selectedItemIndex);
		},
		getInvoiceLineAmount (line) {
			const lineAmount = parseFloat(line.amount.value) || 0;
			return line.itemType?.value === InvoiceLineType.Discount ? -Math.abs(lineAmount) : lineAmount;
		},
		getInvoiceLineTaxValue (line) {
			return parseFloat(line.itemType?.value === InvoiceLineType.SalesTax ? line.amount.value : line.tax.value) || 0;
		},
		getInvoiceLineDiscountValue (line) {
			return Math.abs(parseFloat(line.itemType?.value === InvoiceLineType.Discount ? line.amount?.value : line.discountAmount?.value) || 0);
		},
		onInputInvoiceNumber () {
			this.$v.recognizedData.invoiceNumber.$touch();
			this.isInvoiceNumberInvalid = this.$v.recognizedData.invoiceNumber.value.$error;
		},
	},
};
</script>

<style lang="scss" scoped>
.invoice-sandwich {
  font-size: 13px;
}

.items-list {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
}

.multiple-treatments {
  line-height: 30px;
  border: 2px solid $primary;

  &.active {
    border: 2px solid $active-color;
  }
}

.fas.fa-pencil-alt {
  font-size: 18px;
  color: $primary;
}

.active-sandwich-height {
  height: calc(100% - 67px);
}

.close {
  cursor: pointer;
}

.duplicate-invoice-icon {
  color: white;
  font-size: 20px;
  width: 43px;
  padding-left: 19px;
  padding-top: 11px;
  background-color: var(--backgroundWarning);
  border-radius: 30px;
}

.lines-group {
  margin-bottom: 10px;

  .invoice-line-item:not(:last-child) {
    margin-bottom: 6px;
  }

  &.default {
    border: 2px solid #3583C7;

    .invoice-line-item:first-child {
      border-bottom: 2px solid #3583C7;
    }
  }

  &.duplicate {
    border: 2px solid #FFD3BE;

    .invoice-line-item:first-child {
      border-bottom: 2px solid #FFD3BE;
    }
  }

  &.highlighted {
    border: 2px solid $active-color;

    .invoice-line-item:first-child {
      border-bottom: 2px solid $active-color;
    }
  }
}

.line {
  margin-bottom: 10px;
  border: 1px solid transparent;

  &.highlighted {
    border: 1px solid $active-color;
  }
}
</style>
