<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Split treatment line into multiple ailments"
			modal-width="1500"
		>
			<div class="modal-content w-100">
				<div class="p-20 mb-35">
					<div class="no-gutters">
						<div class="row d-flex align-items-center">
							<span class="text-bold ml-15 pr-0">Enter number of copies (max 5):</span>
							<aq-max-min-number-input
								data-qa="treatmentLinesModal_input_copiesNumber"
								class="mx-10"
								:min-number="0"
								:max-number="5"
								input-class="control-container copies-number-input"
								v-model="copiesNumber"
							/>
							<div class="col-2">
								<aq-checkbox
									data-qa="treatmentLinesModal_checkbox_splitAmountsEqually"
									label="Split amounts equally"
									v-model="splitAmountsEqually"
								/>
							</div>
							<button
								type="button"
								@click="onCopy"
								class="btn btn-secondary"
							>
								Enter
							</button>
						</div>
						<div class="horizontal-rule mt-15" />
						<div v-if="lineItemCopies.length">
							<div class="row mb-15 mt-20">
								<div class="col-2 text-bold">
									Original treatment line:
								</div>
							</div>
							<div
								v-for="(lineItemCopy, index) of lineItemCopies"
								:key="index"
								:class="[ index && index === hoveredLineItemCopy ? 'border-treatment-line' : 'border-invisible', !copiesCreated ? 'transparent' : '']"
								class="d-flex align-items-center row pl-10 pr-10 pt-5 pb-5 mt-2 mb-5 ml-1 mr-1 treatment-line"
								@mouseover="selectItem(index)"
								@mouseleave="unselectItem"
							>
								<aq-read-only-input
									class="col-2 mr-10"
									label="Treatment"
									:description="`${lineItemCopy.quantity.value} x ${lineItemCopy.description.value}`"
								/>
								<aq-select
									data-qa="treatmentLinesModal_select_ailmentCopy"
									class="col-2 mr-10"
									:options="conditions"
									label="Ailment"
									option-label="description"
									track-by="id"
									searchable
									:is-valid="!$v.lineItemCopies.$each.$iter[index].claimCondition.value.$error"
									v-model="lineItemCopy.claimCondition.value"
									no-result-message="No Ailment found"
									@input="onSelectAilment(index)"
									:disabled="!copiesCreated"
								/>
								<aq-select
									data-qa="treatmentLinesModal_select_policySectionCopy"
									class="col-2 mr-10"
									:options="conditionPolicySections(lineItemCopy)"
									label="Policy Section"
									option-label="description"
									track-by="id"
									searchable
									:is-valid="!$v.lineItemCopies.$each.$iter[index].policySection.value.$error"
									v-model="lineItemCopy.policySection.value"
									no-result-message="No Policy Section found"
									@input="$v.lineItemCopies.$each.$iter[index].policySection.value.$touch"
									:disabled="!copiesCreated"
								/>
								<aq-form-input
									data-qa="treatmentLinesModal_input_discountCopy"
									class="col-1 mr-10"
									label="Tax"
									icon-class="text-primary fa-lg"
									type="money"
									:locale="locale"
									v-model="lineItemCopy.tax.value"
									:disabled="!copiesCreated"
								/>
								<aq-form-input
									data-qa="treatmentLinesModal_input_discountCopy"
									class="col-1 mr-10"
									label="Discount"
									icon-class="text-primary fa-lg"
									type="money"
									:locale="locale"
									v-model="lineItemCopy.discountAmount.value"
									:disabled="!copiesCreated"
								/>
								<div class="col-2 mr-10 px-0">
									<aq-form-input
										data-qa="treatmentLinesModal_input_amountCopy"
										label="Amount"
										:class="{'border-deduction': lineItemCopy.selectedUserDeductions.length}"
										icon-class="text-primary fa-lg"
										type="money"
										:locale="locale"
										v-model="lineItemCopy.amount.value"
										:disabled="!copiesCreated"
									/>
									<div
										v-if="lineItemCopy.selectedUserDeductions.length"
										class="deductions"
										v-tooltip="{
											content: lineItemCopy.selectedUserDeductions.map(d => d.description).join(', '),
											classes: [ 'tooltip-red' ]
										}"
									> D
									</div>
								</div>
								<aq-select
									:options="conditionDeductionTypes(lineItemCopy)"
									class="col"
									label="User Deduction"
									option-label="description"
									track-by="id"
									multiple
									hide-tags
									v-model="lineItemCopy.selectedUserDeductions"
									no-result-message="No User Deduction found"
									:disabled="!copiesCreated"
								/>
								<div
									data-qa="treatmentLinesModal_button_removeCopy"
									v-if="index && index === hoveredLineItemCopy"
									class="circle-btn yellow d-flex align-items-center"
									@click="onDelete(index)"
									v-tooltip="{
										content: 'Delete Item',
										classes: [ 'tooltip-orange' ]
									}"
								>
									<i class="aqv-delete m-auto" />
								</div>
								<div v-if="index === 0" class="w-100 mt-15 mb-15 dashed-line" />
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						data-qa="treatmentLinesModal_button_cancel"
						type="button"
						@click="onCancel"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						data-qa="treatmentLinesModal_button_create"
						type="button"
						@click="onSave"
						:disabled="!copiesCreated || $v.$invalid"
						class="btn btn-success ml-auto"
					>
						Create
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import AqMaxMinNumberInput from '@commonView/Shared/AqMaxMinNumberInput';
import AqReadOnlyInput from '@commonView/Shared/AqReadOnlyInput';
import cloneDeep from 'lodash.clonedeep';
import { required } from 'vuelidate/lib/validators';

export default {
	components: {
		AqMaxMinNumberInput,
		AqReadOnlyInput,
	},
	data () {
		return {
			hoveredLineItemCopy: null,
			copiesNumber: 0,
			lineItemCopies: [],
			splitAmountsEqually: false,
			workingCopy: {},
		};
	},
	props: {
		selectedLineItem: {
			type: Object,
			required: true,
		},
		policySections: {
			type: Array,
			required: true,
		},
		conditions: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		deductions: {
			type: Array,
			required: true,
		},
	},
	validations () {
		return {
			lineItemCopies: {
				$each: {
					claimCondition: {
						value: {
							required,
						},
					},
					policySection: {
						value: {
							required,
						},
					},
				},
			},
		};
	},
	computed: {
		copiesCreated () {
			return this.lineItemCopies.length > 1;
		},
		totalCopiesCount () {
			// Including Original
			return this.copiesNumber + 1;
		},
	},
	mounted () {
		this.workingCopy = cloneDeep(this.selectedLineItem);
		this.workingCopy.amount.value = this.selectedLineItem.amount.value || 0;
		this.workingCopy.discountAmount.value = this.selectedLineItem.discountAmount.value || 0;
		this.workingCopy.tax.value = this.selectedLineItem.tax.value || 0;
		this.workingCopy.selectedUserDeductions = this.selectedLineItem.deductions?.map((deduction) => {
			const deductionData = this.deductions.find(d => d.id === deduction.value?.id);
			return {
				id: deductionData.id,
				description: deductionData.description,
			};
		}) ?? [];
		this.lineItemCopies.push(cloneDeep(this.workingCopy));
	},
	methods: {
		conditionPolicySections (lineItem) {
			return this.policySections.filter(ps => ps.claimConditionId === lineItem.claimCondition?.value?.id);
		},
		conditionDeductionTypes (lineItem) {
			return this.deductions.filter(d => d.claimConditionId === lineItem.claimCondition?.value?.id && !d.isSystem);
		},
		onSave () {
			this.lineItemCopies.forEach((lineItemCopy) => {
				lineItemCopy.deductions = lineItemCopy.selectedUserDeductions?.map((deduction, index) => {
					return {
						value: {
							id: deduction.id,
							value: deduction.description,
							amount: index === 0 && lineItemCopy.amount.value ? lineItemCopy.amount.value : 0,
						},
					};
				}) ?? [];
			});

			this.$emit('create-line-item-copies', this.lineItemCopies);
			this.$modal.hide(this.$parent.name);
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onDelete (index) {
			this.lineItemCopies.splice(index, 1);
			this.copiesNumber--;
		},
		onCopy () {
			const copies = new Array(this.totalCopiesCount);

			const amount = this.calculateAmounts(this.workingCopy.amount.value);
			const discountAmount = this.calculateAmounts(this.workingCopy.discountAmount.value);
			const tax = this.calculateAmounts(this.workingCopy.tax.value);

			for (let i = 0; i < this.totalCopiesCount; i++) {
				copies[i] = cloneDeep(this.workingCopy);
				copies[i].id = i === 0 ? this.workingCopy.id : 0;
				copies[i].parentId = i === 0 ? this.workingCopy.parentId : this.workingCopy.id;
				copies[i].amount.value = i === 0 ? amount.original : amount.copy;
				copies[i].discountAmount.value = i === 0 ? discountAmount.original : discountAmount.copy;
				copies[i].tax.value = i === 0 ? tax.original : tax.copy;
			}
			this.lineItemCopies = copies;
		},
		calculateAmounts (originalAmount) {
			const amount = originalAmount / this.totalCopiesCount;
			const splitCopy = Math.floor(amount * 100) / 100;
			const calculatedTotal = splitCopy * this.totalCopiesCount;
			// Find remainder and add it to the splitCopy amount so that the sum of all amounts is equal to the original amount.
			const splitOriginal = splitCopy + originalAmount - calculatedTotal;
			return {
				original: this.splitAmountsEqually ? splitOriginal : originalAmount,
				copy: this.splitAmountsEqually ? splitCopy : originalAmount,
			};
		},
		selectItem (item) {
			this.hoveredLineItemCopy = item;
		},
		unselectItem () {
			this.hoveredLineItemCopy = null;
		},
		onSelectAilment (index) {
			this.$v.lineItemCopies.$each.$iter[index].claimCondition.value.$touch();
			this.$v.lineItemCopies.$each.$iter[index].policySection.value.$model = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.dashed-line {
  border-bottom: 3px dashed #d7f0ff;
}

.treatment-line {
  position: relative;
}

.border-treatment-line {
  border: 1px solid #fc7602;
}

.border-invisible {
  border: 1px solid transparent;
}

.circle-btn {
  position: absolute;
  right: -17px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 31px;
  cursor: pointer;

  &.yellow {
    color: #ffff;
    background-color: #fc7602;

    &:hover {
      color: #ffff;
      background-color: darken(#fc7602, 10%);
    }
  }
}

::v-deep .copies-number-input {
  width: 55px;
  min-height: 38px;
  border: none;
  outline: none;
  resize: none;
  cursor: text;
}

.horizontal-rule {
  border-bottom: 3px solid #d7f0ff;
}

.transparent {
  opacity: 0.71;
}

.deductions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: $danger;
  border-radius: 10px;
  color: #fff;
  padding-left: 5px;
  padding-top: 2px;
}

.border-deduction {
  border: 1px solid $danger;
}
</style>
